/* line 17, ../src/please-wait.scss */
body.pg-loading {
  overflow: hidden;
}

/* line 21, ../src/please-wait.scss */
.pg-loading-screen {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000000;
  opacity: 1;
  background-color: #FFF;
  -webkit-transition: background-color 0.4s ease-in-out 0s;
  -moz-transition: background-color 0.4s ease-in-out 0s;
  -ms-transition: background-color 0.4s ease-in-out 0s;
  -o-transition: background-color 0.4s ease-in-out 0s;
  transition: background-color 0.4s ease-in-out 0s;
}
/* line 32, ../src/please-wait.scss */
.pg-loading-screen.pg-loaded {
  opacity: 0;
  -webkit-animation: pgAnimLoaded 0.5s cubic-bezier(0.7, 0, 0.3, 1) both;
  -moz-animation: pgAnimLoaded 0.5s cubic-bezier(0.7, 0, 0.3, 1) both;
  -ms-animation: pgAnimLoaded 0.5s cubic-bezier(0.7, 0, 0.3, 1) both;
  -o-animation: pgAnimLoaded 0.5s cubic-bezier(0.7, 0, 0.3, 1) both;
  animation: pgAnimLoaded 0.5s cubic-bezier(0.7, 0, 0.3, 1) both;
}
/* line 38, ../src/please-wait.scss */
.pg-loading-screen.pg-loading .pg-loading-logo-header, .pg-loading-screen.pg-loading .pg-loading-html {
  opacity: 1;
}
/* line 42, ../src/please-wait.scss */
.pg-loading-screen.pg-loading .pg-loading-logo-header, .pg-loading-screen.pg-loading .pg-loading-html:not(.pg-loaded) {
  -webkit-animation: pgAnimLoading 1s cubic-bezier(0.7, 0, 0.3, 1) both;
  -moz-animation: pgAnimLoading 1s cubic-bezier(0.7, 0, 0.3, 1) both;
  -ms-animation: pgAnimLoading 1s cubic-bezier(0.7, 0, 0.3, 1) both;
  -o-animation: pgAnimLoading 1s cubic-bezier(0.7, 0, 0.3, 1) both;
  animation: pgAnimLoading 1s cubic-bezier(0.7, 0, 0.3, 1) both;
}
/* line 46, ../src/please-wait.scss */
.pg-loading-screen.pg-loading .pg-loading-html:not(.pg-loaded) {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -ms-animation-delay: 0.3s;
  -o-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
/* line 51, ../src/please-wait.scss */
.pg-loading-screen .pg-loading-inner {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: static;
}
/* line 59, ../src/please-wait.scss */
.pg-loading-screen .pg-loading-center-outer {
  width: 100%;
  padding: 0;
  display: table !important;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}
/* line 70, ../src/please-wait.scss */
.pg-loading-screen .pg-loading-center-middle {
  padding: 0;
  vertical-align: middle;
  display: table-cell  !important;
  margin: 0;
  text-align: center;
}
/* line 78, ../src/please-wait.scss */
.pg-loading-screen .pg-loading-logo-header, .pg-loading-screen .pg-loading-html {
  width: 100%;
  opacity: 0;
}
/* line 83, ../src/please-wait.scss */
.pg-loading-screen .pg-loading-logo-header {
  text-align: center;
}
/* line 86, ../src/please-wait.scss */
.pg-loading-screen .pg-loading-logo-header img {
  display: inline-block !important;
}
/* line 91, ../src/please-wait.scss */
.pg-loading-screen .pg-loading-html {
  margin-top: 90px;
}
/* line 94, ../src/please-wait.scss */
.pg-loading-screen .pg-loading-html.pg-loaded {
  -webkit-transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  -ms-transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  -o-transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);
}
/* line 97, ../src/please-wait.scss */
.pg-loading-screen .pg-loading-html.pg-loaded.pg-removing {
  opacity: 0;
}
/* line 101, ../src/please-wait.scss */
.pg-loading-screen .pg-loading-html.pg-loaded.pg-loading {
  opacity: 1;
}

@-webkit-keyframes pgAnimLoading {
  from {
    opacity: 0;
  }
}
@-moz-keyframes pgAnimLoading {
  from {
    opacity: 0;
  }
}
@-o-keyframes pgAnimLoading {
  from {
    opacity: 0;
  }
}
@-ms-keyframes pgAnimLoading {
  from {
    opacity: 0;
  }
}
@keyframes pgAnimLoading {
  from {
    opacity: 0;
  }
}
@-webkit-keyframes pgAnimLoaded {
  from {
    opacity: 1;
  }
}
@-moz-keyframes pgAnimLoaded {
  from {
    opacity: 1;
  }
}
@-o-keyframes pgAnimLoaded {
  from {
    opacity: 1;
  }
}
@-ms-keyframes pgAnimLoaded {
  from {
    opacity: 1;
  }
}
@keyframes pgAnimLoaded {
  from {
    opacity: 1;
  }
}
